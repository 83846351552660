import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ScoreListStyle } from "./style";
import { Button, Modal, Pagination, Spin, Tabs, message } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { queryUrl } from "utils/functions/common";
import { apiGetNewScoreWorkList, apiGetScoreWorkTaskDetail, apiPostUpdateScoreList, apiSubmitWorksScore } from "api/contest";
import { ScoreTaskDetail, ScoreWorkListItem } from "types/contest";
import ScoreWorkCard from "components/uni-card/self-cards/scoreWorkCard";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { NoData } from "components/no-data";


function NewScoreList() {
  const navigate = useNavigate();
  const localUser = JSON.parse(localStorage.getItem("user_info") || "{}");
  const { id } = useParams();
  const [list, setList] = useState<ScoreWorkListItem[]>([]);
  const [taskDetail, setTaskDetail] = useState<ScoreTaskDetail>();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isDragDisabled, setIsDragDisabled] = useState(true)

  let [searchParams, setSearchParams] = useSearchParams();
  let tab = searchParams.get("tab") || 'D';
  let cat = Number(searchParams.get("cat"));
  let scoreFilter = Number(searchParams.get("scoreFilter")) || undefined;
  const [scoreList, setScoreList] = useState<{ name: string, level: string, sortScore: number, basicScore: number }[]>([])

  const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
    background: isDraggingOver ? "lightblue" : "#fff",
    overflow: "hidden",
  });

  useEffect(() => {
    if (!localUser?.permissions?.includes('workScore')) {
      messageApi.open({
        type: 'error',
        content: '当前登录用户没有权限！',
      });
      return
    }
    getTaskDetail();
    getList();
  }, [id, searchParams]);

  const getList = async () => {
    setLoading(true);
    const res = await apiGetNewScoreWorkList({
      mediaCategory: cat, //媒体类型
      needTotal: 1, //是否需要总数；默认需要，不需要总时，效率高
      taskId: Number(id), //任务ID
      scoreFilter: scoreFilter,
      level: tab
    });
    setList(res);
    setLoading(false);
  };

  const getTaskDetail = () => {
    apiGetScoreWorkTaskDetail({ taskId: Number(id), mediaCategory: cat }).then((res) => {
      setTaskDetail(res);
      if (!res?.scoreRules) {
        return
      }
      const scoreRules = JSON.parse(res.scoreRules)
      setScoreList(scoreRules)
    });
  };


  const finalSubmit = () => {
    apiSubmitWorksScore({
      "mediaCategory": cat,
      "taskId": Number(id)
    }).then(() => {
      message.success('提交成功!')
    })
  }

  const reorder = (
    list: ScoreWorkListItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    console.log(source)

    const sourceIndex = list.findIndex(
      (item) => item.id === Number(source.droppableId)
    );
    const destinationIndex = list.findIndex(
      (item) => item.id === Number(destination.droppableId)
    );

    if (sourceIndex !== destinationIndex) {
      const newFileList = reorder(list, sourceIndex, destinationIndex);
      setList(newFileList);
    }

  };

  const changeTab = (e: any) => {
    if (!isDragDisabled) {
      Modal.confirm({
        title: '提示',
        content: '当前处于编辑模式，是否保存当前内容',
        okText: "确定",
        cancelText: "取消",
        onCancel() {
          const query = queryUrl({ tab: e })
          navigate(`?${query}`)
          setIsDragDisabled(true)
        },
        centered: true,
        onOk() {
          apiPostUpdateScoreList({ mediaCategory: cat, scoreLevel: tab, taskId: Number(id), workIds: list.map(item => item.id) }).then(() => {
            message.success("保存成功")
            const query = queryUrl({ tab: e })
            navigate(`?${query}`)
            setIsDragDisabled(true)
          })
        },
      });
      return
    }
    const query = queryUrl({ tab: e })
    navigate(`?${query}`)
  }

  // 保存拖拽后顺序
  const saveUpdateList = () => {
    console.log(list)
    apiPostUpdateScoreList({ mediaCategory: cat, scoreLevel: tab, taskId: Number(id), workIds: list.map(item => item.id) }).then(() => {
      setIsDragDisabled(true)
      message.success("保存成功")
    })
  }

  const clickToTop = (detail: ScoreWorkListItem) => {
    const index = list.findIndex(item => item.id === detail.id)
    if (index >= 0) {
      const copyList: ScoreWorkListItem[] = JSON.parse(JSON.stringify(list))
      copyList.splice(index, 1)
      copyList.unshift(detail)
      setList([...copyList])

      if (!!isDragDisabled) {
        apiPostUpdateScoreList({ mediaCategory: cat, scoreLevel: tab, taskId: Number(id), workIds: copyList.map(item => item.id) }).then(() => {
          message.success("置顶成功")
        })
      }
    }
  }

  const saveListId = () => {
    localStorage.setItem("scoreListIds", JSON.stringify(list.map(item => item.id)))
  }


  return (
    <ScoreListStyle className="score-list">
      {contextHolder}
      <div className="header">
        {taskDetail?.mediaCategories.find((item) => item.id === cat)?.name}
        作品打分列表
      </div>
      <div className="task-info">
        <div className="info-item">
          <div className="label">创作分类</div>
          <div className="content">
            {taskDetail?.mediaCategories.find((item) => item.id === cat)?.name}
          </div>
        </div>

        <div className="info-item">
          <div className="label">开始时间</div>
          <div className="content">
            {taskDetail?.startTime.substring(0, 10)}
          </div>
        </div>
        <div className="info-item">
          <div className="label">结束时间</div>
          <div className="content">{taskDetail?.endTime.substring(0, 10)}</div>
        </div>
        <div className="info-item">
          <div className="label">提交状态</div>
          <div className="content">{taskDetail?.isSubmit ? '已提交' : '未提交'}</div>
        </div>
        <div className="info-item">
          <div className="label">打分评委</div>
          <div className="content">{localUser.username}</div>
        </div>
        <div className="info-item">
          <div className="label">比赛</div>
          <div className="content overflow-hidden" >
            {taskDetail?.dataName}</div>
        </div>
      </div>
      <div className="submit">
        <Button className="btn" type="primary" style={{ width: 200 }} onClick={finalSubmit}>评审完成</Button>
      </div>

      <div className="wrap">
        <div className="score-tab">
          <Tabs
            style={{ width: "100%" }}
            size="large"
            centered
            activeKey={tab}
            onChange={changeTab}
            items={scoreList.map(item => {
              return {
                label: <div className="score-itme" key={item.name}>{item.name}</div>,
                key: item.level
              }
            })}
          />
        </div>
        <div className="tip-wrap">
          <div className="tip">
            <div>所有待评审作品初始默认为D档。</div>
            <div>第一步，请评委老师点击作品封面，前往作品详情后，对作品进行分类ABC档等级分类。（A档为最高评分档作品，D档为最低评分档作品，即A＞B＞C＞D，如部分作品较不满意，可不做任何操作保留至D档即可）
            </div>
            <div>第二步，分档结束后，评委老师可前往对应档位列表中，点击右侧「编辑排序」按钮，即可拖拽调整作品排序（同档位下，作品排序越靠前，则代表其排名分数越高）。<span>拖拽排序后，务必点击保存排序。</span></div>
            <div>第三步，所有档位排序完成后，评委老师检查无误，请点击上方「评审完成」按钮，结束评审环节。</div>
            <div>感谢评委老师的辛勤评审与专业意见！</div>
          </div>
          {
            isDragDisabled && !!list.length && tab !== 'D' && <Button type="primary" onClick={() => setIsDragDisabled(false)}>编辑排序</Button>
          }
          {
            !isDragDisabled && list.length && tab !== 'D' && <Button type="primary" onClick={saveUpdateList}>保存排序</Button>
          }
          {
            !isDragDisabled && <div className="word">当前处于编辑模式，可拖拽调整作品排序</div>
          }
        </div>
      </div>


      <div className="work-list">
        <Spin spinning={loading}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="list">
              {list.map((item, index) => {
                return <Droppable direction="horizontal" key={item.id} droppableId={item.id + ""} type="ITEM">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="drop-wrap"
                    >
                      {taskDetail && <>
                        <Draggable
                          key={item.id}
                          draggableId={item.id + ""}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="drop"
                              onClick={saveListId}
                            >
                              <ScoreWorkCard clickToTop={clickToTop} isShowScore={false} taskMediaCategory={cat} key={index} item={item} needRefresh={getList} scoreTask={taskDetail} isTop={tab !== 'D' && !isDragDisabled}></ScoreWorkCard>
                            </div>
                          )}
                        </Draggable>
                      </>
                      }
                    </div>
                  )}
                </Droppable>
              })
              }
            </div>

          </DragDropContext>
        </Spin>
        {
          list.length === 0 && <NoData />
        }
      </div>
    </ScoreListStyle>
  );
}

export default NewScoreList;
